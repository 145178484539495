// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Grid from "../vanillaui/atoms/Grid.re.mjs";
import * as Util from "../shared/Util.re.mjs";
import * as React from "react";
import * as Avatar from "../catalyst/Avatar.re.mjs";
import * as Layout from "../shared/Layout.re.mjs";
import * as Lingui from "../../locales/Lingui.re.mjs";
import * as Navbar from "../catalyst/Navbar.re.mjs";
import * as Router from "../shared/Router.re.mjs";
import * as Dropdown from "../catalyst/Dropdown.re.mjs";
import * as RelayEnv from "../../entry/RelayEnv.re.mjs";
import * as Localized from "../shared/i18n/Localized.re.mjs";
import * as PageTitle from "../vanillaui/atoms/PageTitle.re.mjs";
import * as EventsList from "../organisms/EventsList.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.re.mjs";
import * as GlobalQuery from "../shared/GlobalQuery.re.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as Core from "@linaria/core";
import * as WaitForMessages from "../shared/i18n/WaitForMessages.re.mjs";
import * as ReactRouterDom from "react-router-dom";
import * as React$1 from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as EventsQuery_graphql from "../../__generated__/EventsQuery_graphql.re.mjs";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.re.mjs";
import * as Solid from "@heroicons/react/24/solid";

import { css, cx } from '@linaria/core'
;

import { t } from '@lingui/macro'
;

var convertVariables = EventsQuery_graphql.Internal.convertVariables;

var convertResponse = EventsQuery_graphql.Internal.convertResponse;

RescriptRelay_Query.useQuery(convertVariables, EventsQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, EventsQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(EventsQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(EventsQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(EventsQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(EventsQuery_graphql.node, convertVariables);

function Events$ActivityDropdownMenu(props) {
  var activities = [
    {
      label: t`All`,
      url: "/"
    },
    {
      label: t`Pickleball`,
      url: "/?activity=pickleball",
      initials: "P"
    },
    {
      label: t`Badminton`,
      url: "/?activity=badminton",
      initials: "B"
    }
  ];
  return JsxRuntime.jsx(Dropdown.DropdownMenu.make, {
              className: "min-w-80 lg:min-w-64",
              anchor: "bottom start",
              children: activities.map(function (a) {
                    return JsxRuntime.jsxs(React.Fragment, {
                                children: [
                                  JsxRuntime.jsxs(Dropdown.DropdownItem.make, {
                                        href: a.url,
                                        children: [
                                          Core__Option.getOr(Core__Option.map(a.initials, (function (initials) {
                                                      return JsxRuntime.jsx(Avatar.make, {
                                                                  className: "bg-purple-500 text-white",
                                                                  slot: "icon",
                                                                  initials: initials
                                                                });
                                                    })), null),
                                          JsxRuntime.jsx(Dropdown.DropdownLabel.make, {
                                                children: a.label
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Dropdown.DropdownDivider.make, {})
                                ]
                              }, a.label);
                  })
            });
}

function Events(props) {
  var query = ReactRouterDom.useLoaderData();
  var match = usePreloaded(query.data);
  var fragmentRefs = match.fragmentRefs;
  var match$1 = ReactRouterDom.useSearchParams();
  var searchParams = match$1[0];
  var activityFilter = Router.SearchParams.get(searchParams, "activity");
  var title;
  if (activityFilter !== undefined) {
    switch (activityFilter) {
      case "badminton" :
          title = t`badminton events`;
          break;
      case "pickleball" :
          title = t`pickleball events`;
          break;
      default:
        title = t`all events`;
    }
  } else {
    title = t`all events`;
  }
  var shadowFilter = Core__Option.getOr(Core__Option.map(Router.SearchParams.get(searchParams, "shadow"), (function (param) {
              return true;
            })), false);
  var viewer = GlobalQuery.useViewer();
  var navigate = ReactRouterDom.useNavigate();
  var searchParams$1 = Router.ImmSearchParams.fromSearchParams(searchParams);
  return JsxRuntime.jsx(WaitForMessages.make, {
              children: (function () {
                  return JsxRuntime.jsx(JsxRuntime.Fragment, {
                              children: Caml_option.some(JsxRuntime.jsx(EventsList.make, {
                                        events: fragmentRefs,
                                        header: JsxRuntime.jsx(Layout.Container.make, {
                                              children: JsxRuntime.jsxs(Grid.make, {
                                                    children: [
                                                      JsxRuntime.jsxs(PageTitle.make, {
                                                            children: [
                                                              title,
                                                              JsxRuntime.jsxs(React$1.Menu, {
                                                                    children: [
                                                                      JsxRuntime.jsx(Dropdown.DropdownButton.make, {
                                                                            as: Navbar.NavbarItem.make,
                                                                            children: JsxRuntime.jsx(Solid.ChevronDownIcon, {})
                                                                          }),
                                                                      JsxRuntime.jsx(Events$ActivityDropdownMenu, {})
                                                                    ]
                                                                  }),
                                                              Core__Option.getOr(Core__Option.map(Core__Option.flatMap(viewer.user, (function (user) {
                                                                              return Core__Array.indexOfOpt([
                                                                                          "Hasby Riduan",
                                                                                          "hasbyriduan9",
                                                                                          "notchrischen",
                                                                                          "Matthew",
                                                                                          "David Vo",
                                                                                          "Kai",
                                                                                          "Alex Ng"
                                                                                        ], Core__Option.getOr(user.lineUsername, ""));
                                                                            })), (function (param) {
                                                                          return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                                                      children: [
                                                                                        " ",
                                                                                        JsxRuntime.jsx(ReactRouterDom.Link, {
                                                                                              to: "/events/create",
                                                                                              children: "+"
                                                                                            })
                                                                                      ]
                                                                                    });
                                                                        })), null)
                                                            ]
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsxs(React$1.Switch.Group, {
                                                                  as: "div",
                                                                  className: "flex items-center",
                                                                  children: [
                                                                    JsxRuntime.jsx(React$1.Switch, {
                                                                          className: Core.cx(shadowFilter ? "bg-indigo-600" : "bg-gray-200", "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"),
                                                                          children: JsxRuntime.jsx("span", {
                                                                                "aria-hidden": true,
                                                                                className: Core.cx(shadowFilter ? "translate-x-5" : "translate-x-0", "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out")
                                                                              }),
                                                                          checked: shadowFilter,
                                                                          onChange: (function (v) {
                                                                              if (v) {
                                                                                navigate("./?" + searchParams$1.set("shadow", "true").toString(), undefined);
                                                                                return ;
                                                                              } else {
                                                                                return navigate("./?" + searchParams$1.delete("shadow").toString(), undefined);
                                                                              }
                                                                            })
                                                                        }),
                                                                    JsxRuntime.jsxs(React$1.Switch.Label, {
                                                                          as: "span",
                                                                          className: "ml-3 text-sm",
                                                                          children: [
                                                                            JsxRuntime.jsx("span", {
                                                                                  children: t`include private`,
                                                                                  className: "font-medium text-gray-900"
                                                                                }),
                                                                            " "
                                                                          ]
                                                                        })
                                                                  ]
                                                                })
                                                          })
                                                    ]
                                                  })
                                            })
                                      }))
                            });
                })
            });
}

var LoaderArgs = {};

function loadMessages(lang) {
  var tmp = lang === "ja" ? import("../../locales/src/components/pages/Events.re/ja") : import("../../locales/src/components/pages/Events.re/en");
  return [tmp.then(function (messages) {
                React.startTransition(function () {
                      Lingui.i18n.load(lang, messages.messages);
                    });
              })];
}

async function loader(param) {
  var params = param.params;
  var url = new URL(param.request.url);
  var after = Router.SearchParams.get(url.searchParams, "after");
  var before = Router.SearchParams.get(url.searchParams, "before");
  var activity = Router.SearchParams.get(url.searchParams, "activity");
  var shadow = Core__Option.map(Router.SearchParams.get(url.searchParams, "shadow"), (function (param) {
          return true;
        }));
  var afterDate = Core__Option.map(Router.SearchParams.get(url.searchParams, "afterDate"), (function (d) {
          return Util.Datetime.fromDate(new Date(d));
        }));
  if (import.meta.env.SSR) {
    await Localized.loadMessages(params.lang, loadMessages);
  }
  return {
          data: EventsQuery_graphql.load(RelayEnv.getRelayEnv(param.context, import.meta.env.SSR), {
                after: after,
                afterDate: afterDate,
                before: before,
                filters: {
                  activitySlug: activity,
                  shadow: shadow
                }
              }, "store-or-network", undefined, undefined),
          i18nLoaders: import.meta.env.SSR ? undefined : Caml_option.some(Localized.loadMessages(params.lang, loadMessages))
        };
}

var make = Events;

var Component = Events;

export {
  make ,
  Component ,
  LoaderArgs ,
  loader ,
}
/*  Not a pure module */
